.ant-tabs-ink-bar {
    background: #b57e36 !important;
}

.ant-tabs-nav-list {
    line-height: none;
}

.ant-tabs-nav {
    width: 90%;
}


.ant-tabs {
    &.detailTab {
        height: 85%;
        .ant-tabs-content-holder {
            height: calc(100% - 100px);
            overflow-y: auto;
            padding: 0px 0 10px 0 !important;
        }

        h5 {
            margin-bottom: 5px !important;
            font-size: 12px;
        }
    }
}