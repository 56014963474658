.ant-menu {
  background: transparent !important;
}
.ant-menu .ant-menu-submenu-title {
  background: rgba(164, 105, 40, 0.8);
}
.ant-menu .ant-menu-submenu-title .ant-menu-title-content {
  font-weight: bold !important;
  color: #fff !important;
}
.ant-menu .ant-menu-item {
  background: #c3945f;
}
.ant-menu .ant-menu-item.activated {
  background: #A46928 !important;
}
.ant-menu .ant-menu-item.ant-menu-item-selected {
  background: #A46928;
}
.ant-menu .ant-menu-item .ant-menu-title-content {
  font-weight: bold !important;
  color: #fff !important;
}

.ant-select {
  height: 100% !important;
}
.ant-select .ant-select-selector {
  padding: 10px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}

.ant-input-disabled {
  background-color: rgba(217, 217, 217, 0.6588235294) !important;
  color: rgba(0, 0, 0, 0.68) !important;
}

.ant-input {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}

.ant-input-password .ant-input {
  box-shadow: unset !important;
}

.ant-select.toolbarRoomSelect .ant-select-selector {
  padding: 1px 5px !important;
}

.ant-input.input-error,
.ant-select.input-error {
  border: 1px solid #e51818;
  box-shadow: rgba(241, 137, 137, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}

.ant-select.no-padding .ant-select-selector {
  padding: 0px 10px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}

.ant-otp-input {
  font-size: 15px;
  padding: 10px;
}

@media screen and (max-width: 1350px) {
  .ant-otp-input {
    font-size: 13px !important;
    padding: 6px !important;
  }
}
.common-rooms {
  height: 250px;
  overflow: hidden auto;
  background: #7D4302;
}
.common-rooms h4 {
  font-weight: 400;
  font-size: 50px;
  line-height: 30px;
}
.common-rooms-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.common-rooms-content h5 {
  font-size: 30px;
}
.common-rooms-content .common-price,
.common-rooms-content .common-status {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
}

.avatar-player-status {
  animation-name: fadeInDropdown2;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.25, 0.5, 2.25);
  animation-fill-mode: forwards;
}

.avatar-player-status-infinite {
  animation-name: fadeInDropdown2;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.25, 0.5, 2.25);
  animation-fill-mode: forwards;
}

@keyframes fadeInDropdown {
  0% {
    height: 0;
    opacity: 0;
  }
  30% {
    opacity: 1;
    height: 30px;
  }
  80% {
    opacity: 1;
    height: 30px;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
@media screen and (max-width: 1200px) {
  @keyframes fadeInDropdown2 {
    0% {
      height: 0;
      opacity: 0;
    }
    30% {
      opacity: 1;
      height: 20px;
    }
    90% {
      opacity: 1;
      height: 20px;
    }
    100% {
      height: 0;
      opacity: 0;
    }
  }
}
@media screen and (min-width: 1201px) {
  @keyframes fadeInDropdown2 {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      opacity: 1;
      height: 25px;
    }
  }
}
.myTurn {
  background: linear-gradient(to right, #fff 50%, #FFDF00 50%);
  border-radius: 100%;
  animation: loader-circle-time 15s steps(800, start);
}

.myTurn:after {
  content: "";
  border-radius: 100% 0 0 100%/50% 0 0 50%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
  animation: loader-circle-mask 15s steps(250, start);
  transform-origin: 100% 50%;
}

@keyframes loader-circle-time {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-circle-mask {
  0% {
    background: #FFDF00;
    transform: rotate(0deg);
  }
  50% {
    background: #FFDF00;
    transform: rotate(-180deg);
  }
  50.01% {
    background: #fff;
    transform: rotate(0deg);
  }
  100% {
    background: #fff;
    transform: rotate(-180deg);
  }
}
.startGame {
  animation-name: fadeOut;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  display: none;
}
.startGame span {
  color: #FFDF00;
  font-size: 70px;
  letter-spacing: 10px;
  font-weight: bold;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-switch {
  height: 24px;
}
.ant-switch .ant-switch-handle::before {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
}
.ant-switch.ant-switch-checked {
  background-color: #000;
}
.ant-switch.ant-switch-checked:hover {
  background-color: #1d1d1d !important;
}
.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #c73636 !important;
  color: #fff;
}

.ant-tabs-ink-bar {
  background: #b57e36 !important;
}

.ant-tabs-nav-list {
  line-height: none;
}

.ant-tabs-nav {
  width: 90%;
}

.ant-tabs.detailTab {
  height: 85%;
}
.ant-tabs.detailTab .ant-tabs-content-holder {
  height: calc(100% - 100px);
  overflow-y: auto;
  padding: 0px 0 10px 0 !important;
}
.ant-tabs.detailTab h5 {
  margin-bottom: 5px !important;
  font-size: 12px;
}

.ant-popover-inner {
  padding: 0 !important;
}

.toolbarPopover .ant-popover-inner {
  border: 1px solid yellow !important;
}

.toaster {
  max-width: 450px;
}

@media screen and (max-width: 1100px) {
  .toaster {
    max-width: 350px !important;
  }
}
.ant-slider-rail {
  background-color: white !important;
}

.ant-modal.no-padding {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.ant-modal.no-padding .ant-modal-content {
  padding: 0;
  border-radius: 20px;
}

.ant-table-wrapper {
  height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading {
  height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  height: calc(100% - 60px);
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container {
  height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content {
  height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table {
  height: 100%;
  max-height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table thead, .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead .ant-table-cell {
  background-color: #A46928;
  color: #fff;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody {
  display: block;
  height: calc(100% - 55px);
  overflow: auto !important;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row:nth-child(odd) {
  background-color: #e6e6e6 !important;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-cell.ant-table-cell-row-hover {
  background-color: rgba(187, 131, 67, 0.6039215686) !important;
  color: white;
  cursor: pointer;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-cell {
  padding: 10px !important;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-cell.ant-table-selection-column {
  width: 60px !important;
}
.ant-table-wrapper .ant-pagination-options .ant-select .ant-select-selector {
  padding: 1px 10px !important;
  cursor: pointer;
}

.btnFilterControl {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 20px;
  border: 1px solid #fff;
  outline: none;
  background-color: #A46928;
  color: #fff;
  padding: 10px;
}

.throwingCard_Player {
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.throwingCard_Player-1 {
  animation-name: throwingCard1;
}

.throwingCard_Player-2 {
  animation-name: throwingCard2;
}

.throwingCard_Player-3 {
  animation-name: throwingCard3;
}

.throwingCard_Player-4 {
  animation-name: throwingCard4;
}

.throwingCard_Player-5 {
  animation-name: throwingCard5;
}

.throwingCard_Player-6 {
  animation-name: throwingCard6;
}

.throwingCard_Player-7 {
  animation-name: throwingCard7;
}

@keyframes throwingCard1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 47%;
    left: 75%;
    transform: translateZ(50%);
  }
}
@keyframes throwingCard2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 60%;
    left: 75%;
    transform: translateZ(50%);
  }
}
@keyframes throwingCard3 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 70%;
    left: 75%;
    transform: translateZ(50%);
  }
}
@keyframes throwingCard4 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 80%;
    transform: translateZ(50%);
  }
}
@keyframes throwingCard5 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 70%;
    left: 30%;
    transform: translateZ(50%);
  }
}
@keyframes throwingCard6 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 65%;
    left: 20%;
    transform: translateZ(50%);
  }
}
@keyframes throwingCard7 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 47%;
    left: 20%;
    transform: translateZ(50%);
  }
}/*# sourceMappingURL=style.css.map */