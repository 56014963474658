@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
}

.cube-loader {
    position: relative;
    width: 40px;
    height: 40px;
    margin: auto;
  }
  
  .cube {
    position: absolute;
    width: 50%;
    height: 50%;
    background-color: #333;
    border: 2px solid #fff;
    animation: foldCube 2.4s infinite linear;
  }
  
  /* Individual cube positioning */
  .cube1 {
    top: 0;
    left: 0;
    transform-origin: 100% 100%;
  }
  .cube2 {
    top: 0;
    right: 0;
    transform-origin: 0 100%;
  }
  .cube3 {
    bottom: 0;
    right: 0;
    transform-origin: 0 0;
  }
  .cube4 {
    bottom: 0;
    left: 0;
    transform-origin: 100% 0;
  }
  
  /* Keyframes for the folding animation */
  @keyframes foldCube {
    0%,
    10% {
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%,
    75% {
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%,
    100% {
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
  
  /* Animation delay for each cube */
  .cube1 {
    animation-delay: 0.3s;
  }
  .cube2 {
    animation-delay: 0.6s;
  }
  .cube3 {
    animation-delay: 0.9s;
  }
  .cube4 {
    animation-delay: 1.2s;
  }
  
  .down-arrow {
    animation: jumpInfinite 1.5s infinite;
  }

  @keyframes jumpInfinite {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  button {
    cursor: pointer;
  }

  .text-hightlight {
    color: #fff;
    font-weight: bold;
    -webkit-text-stroke: 1px #f10934;
    text-shadow: red 2px 2px;
  }

  .text-hightlight-winner {
    /* color: #fff; */
    font-weight: bold;
    /* -webkit-text-stroke: 1px #FFDF00; */
    /* text-shadow: #FFDF00 2px 2px; */
  }

  .winner234 {
    animation-name: domDomPhatSang;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  @keyframes fadeOut2 {
    0% {
      left: 100%;
      opacity: 0.5;
    }
    100% {
      left: calc(100% - 300px);
      opacity: 1;
    }
  }

  @keyframes domDomPhatSang {
    0% {
      box-shadow: none;
    }
    30% {
      box-shadow: rgb(255, 223, 1, 0.7) 0px 54px 55px, rgba(255, 223, 1, 0.5) 0px -12px 30px, rgba(255, 223, 1, 0.4) 0px 4px 6px, rgba(255, 223, 1, 0.6) 0px 12px 13px, rgba(255, 223, 1, 0.2) 0px -3px 5px;
    } 70% {
      box-shadow: rgb(255, 223, 1, 0.3) 0px 54px 55px, rgba(255, 223, 1, 0.2) 0px -12px 30px, rgba(255, 223, 1, 0.2) 0px 4px 6px, rgba(255, 223, 1, 0.2) 0px 12px 13px, rgba(255, 223, 1, 0.1) 0px -3px 5px;
    } 100% {
      box-shadow: none;
    }
  }

  .room-table-admin tr td,
  .room-table-admin tr th  {
    padding: 6px 20px;
    text-align: center;
    border: 1px solid #A46928;
  }

  @media screen and (max-width: 1300px) {
    .room-table-admin tr td,
    .room-table-admin tr th  {
      padding: 4px 10px;
      font-size: 13px !important;
    }
  }

  button {
    cursor: pointer;
  }

  .btn-shadow {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }

  .continute-button {
    position: relative;
    width: 11em;
    height: 4em;
    outline: none;
    transition: 0.1s;
    background-color: transparent;
    border: none;
    font-size: 13px;
    font-weight: bold;
    color: #ddebf0;
  }
  
  #continute-button-clip {
    --color: #2761c3;
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 5px double var(--color);
    box-shadow: inset 0px 0px 15px #195480;
    -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    box-sizing: border-box;
  }
  
  .continute-button-arrow {
    position: absolute;
    transition: 0.2s;
    background-color: #2761c3;
    top: 35%;
    width: 11%;
    height: 30%;
  }
  
  #continute-button-leftArrow {
    left: -13.5%;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%);
  }
  
  #continute-button-rightArrow {
    -webkit-clip-path: polygon(100% 49%, 0 0, 0 100%);
    left: 102%;
  }
  
  .continute-button:hover #continute-button-rightArrow {
    background-color: #27c39f;
    left: -15%;
    animation: 0.6s ease-in-out both infinite alternate rightArrow8;
  }
  
  .continute-button:hover #continute-button-leftArrow {
    background-color: #27c39f;
    left: 103%;
    animation: 0.6s ease-in-out both infinite alternate leftArrow8;
  }
  
  .continute-button-corner {
    position: absolute;
    width: 4em;
    height: 4em;
    background-color: #2761c3;
    box-shadow: inset 1px 1px 8px #2781c3;
    transform: scale(1) rotate(45deg);
    transition: 0.2s;
  }
  
  #continute-button-rightTop {
    top: -1.98em;
    left: 91%;
  }
  
  #continute-button-leftTop {
    top: -1.96em;
    left: -3.0em;
  }
  
  #continute-button-leftBottom {
    top: 2.10em;
    left: -2.15em;
  }
  
  #continute-button-rightBottom {
    top: 45%;
    left: 88%;
  }
  
  .continute-button:hover #continute-button-leftTop {
    animation: 0.1s ease-in-out 0.05s both changeColor8,
    0.2s linear 0.4s both lightEffect8;
  }
  
  .continute-button:hover #continute-button-rightTop {
    animation: 0.1s ease-in-out 0.15s both changeColor8,
    0.2s linear 0.4s both lightEffect8;
  }
  
  .continute-button:hover #continute-button-rightBottom {
    animation: 0.1s ease-in-out 0.25s both changeColor8,
    0.2s linear 0.4s both lightEffect8;
  }
  
  .continute-button:hover #continute-button-leftBottom {
    animation: 0.1s ease-in-out 0.35s both changeColor8,
    0.2s linear 0.4s both lightEffect8;
  }
  
  .continute-button:hover .continute-button-corner {
    transform: scale(1.25) rotate(45deg);
  }
  
  .continute-button:hover #continute-button-clip {
    animation: 0.2s ease-in-out 0.55s both greenLight8;
    --color: #27c39f;
  }
  
  @keyframes changeColor8 {
    from {
      background-color: #2781c3;
    }
  
    to {
      background-color: #27c39f;
    }
  }
  
  @keyframes lightEffect8 {
    from {
      box-shadow: 1px 1px 5px #27c39f;
    }
  
    to {
      box-shadow: 0 0 2px #27c39f;
    }
  }
  
  @keyframes greenLight8 {
    from {
    }
  
    to {
      box-shadow: inset 0px 0px 32px #27c39f;
    }
  }
  
  @keyframes leftArrow8 {
    from {
      transform: translate(0px);
    }
  
    to {
      transform: translateX(10px);
    }
  }
  
  @keyframes rightArrow8 {
    from {
      transform: translate(0px);
    }
  
    to {
      transform: translateX(-10px);
    }
  }
  
  
  
  .hover-salce-medium:hover {
    transform: scale(1.4);
  }

 

  .livenow {
    width: 30px;
    display: inline-block;
  }
  
  .livenow > div {
    vertical-align: middle;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: absolute;
    margin: 0 auto;
    border:3px solid rgb(238, 71, 71);
    -webkit-animation: live 1.4s infinite ease-in-out;
    animation: live 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    &:nth-child(1) {
      background-color:rgba(255,255,255,0.3);
      background-color:rgb(255, 48, 48);
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: 0.16s;
      animation-delay: 0.16s;
    }
    &:nth-child(3) {
      -webkit-animation-delay: 0.42s;
      animation-delay: 0.42s;
      border:3px solid rgba(255,255,255,0.5);
    }
    &:nth-child(4) {
      border:3px solid rgb(255, 16, 16);
      -webkit-animation-delay: -0.42s;
      animation-delay: -0.42s;
    }
  }
  
  @-webkit-keyframes live {
    0%, 80%, 100% { -webkit-transform: scale(0.6) }
    40% { -webkit-transform: scale(1.0) }
  }
  @keyframes live {
    0%, 80%, 100% { 
      transform: scale(0.6);
      -webkit-transform: scale(0.6);
    } 40% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
  

  video::-webkit-media-controls, 
  video::-moz-media-controls, 
  video::-o-media-controls, 
  video::-ms-media-controls {   
    display: none !important; 
  }

  video::-webkit-media-controls-start-playback-button {
    display: none;
}



.commonPopActivated {
  background: #ffdf001f;
  color: #FFDF00;
  border-radius: 5px;
}

/* .dotted-border::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 25px solid red;
} */


