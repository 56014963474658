.ant-menu {
    background: transparent !important;
    .ant-menu-submenu-title {
        background: rgba(164,105,40,0.8);

        .ant-menu-title-content {
            font-weight: bold !important;
            color: #fff !important;
        }
    }

    .ant-menu-item {
        background: #c3945f;

        &.activated {
            background: #A46928 !important;
        }
    
        &.ant-menu-item-selected { 
            background: #A46928;
        }

        .ant-menu-title-content {
            font-weight: bold !important;
            color: #fff !important;
        }
    }
}
