.ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
            height: 100%;

            .ant-table {
                height: calc(100% - 60px);

                .ant-table-container {
                    height: 100%;
 
                    .ant-table-content {
                        height: 100%;

                        table {
                            height: 100%;
                            max-height: 100%;

                            thead, tbody tr {
                                display: table;
                                width: 100%;
                                table-layout: fixed;
                            }

                            .ant-table-thead {
                                .ant-table-cell {
                                    background-color: #A46928;
                                    color: #fff;
                                }
                            }

                            .ant-table-tbody {
                                display: block;
                                height: calc(100% - 55px);
                                overflow: auto !important;
                                
                                .ant-table-row:nth-child(odd) {
                                    background-color: #e6e6e6 !important;
                                }

                                .ant-table-cell {

                                    &.ant-table-cell-row-hover {
                                        background-color: #bb83439a !important;
                                        color: white;
                                        cursor: pointer;
                                    }
                                }
                                
                            }

                            .ant-table-cell {
                                padding: 10px !important; 

                                &.ant-table-selection-column {
                                    width: 60px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-pagination-options {
        .ant-select .ant-select-selector {
            padding: 1px 10px !important;
            cursor: pointer;
        }
    }
}