.ant-popover-inner {
    padding: 0 !important;
}

.toolbarPopover {
    .ant-popover-inner {
        border: 1px solid yellow !important;
    }
}

.toaster {
    max-width: 450px;
}

@media screen and (max-width: 1100px) {
    .toaster {
        max-width: 350px !important;
    }
}