.ant-switch {
    height: 24px;

    .ant-switch-handle {

        &::before {
            // content: "패";
            width: 20px; 
            height: 20px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: bold;
        }
    } 

    &.ant-switch-checked {
  
        &:hover {
            background-color: #1d1d1d !important;
        }

        background-color: #000;
        .ant-switch-handle {

            &::before {
                // content: "승"; 
                background-color: #c73636 !important;
                color: #fff;
            }
        }
    }
}