.btnFilterControl {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px;
    border: 1px solid #fff;
    outline: none;
    background-color: #A46928;
    // background-color: transparent;
    color: #fff;
    padding: 10px;
}