.throwingCard_Player {
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    // animation-iteration-count: infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
}
  
@for $i from 1 through 7 {
    .throwingCard_Player-#{$i} {
        animation-name: throwingCard#{$i};
    }
}

@keyframes throwingCard1 {
    0% {
      opacity: 1;    
    }
    100% {
      opacity: 0;
      top: 47%;
      left: 75%;
      transform: translateZ(50%); 
    }
}

@keyframes throwingCard2 {
    0% {
      opacity: 1;    
    }
    100% {
      opacity: 0;
      top: 60%;
      left: 75%;
      transform: translateZ(50%); 
    }
}

@keyframes throwingCard3 {
    0% {
      opacity: 1;    
    }
    100% {
      opacity: 0;
      top: 70%;
      left: 75%;
      transform: translateZ(50%); 
    }
}

@keyframes throwingCard4 {
    0% {
      opacity: 1;    
    }
    100% {
      opacity: 0;
      top: 80%;
      transform: translateZ(50%); 
    }
}


@keyframes throwingCard5 {
    0% {
      opacity: 1;    
    }
    100% {
      opacity: 0;
      top: 70%;
      left: 30%;
      transform: translateZ(50%); 
    }
}

@keyframes throwingCard6 {
    0% {
      opacity: 1;    
    }
    100% {
      opacity: 0;
      top: 65%;
      left: 20%;
      transform: translateZ(50%); 
    }
}

@keyframes throwingCard7 {
    0% {
      opacity: 1;    
    }
    100% {
      opacity: 0;
      top: 47%;
      left: 20%;
      transform: translateZ(50%); 
    }
}