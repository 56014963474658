.common-rooms {
    // width: 872px;
    height: 250px;
    overflow: hidden auto;
    background: #7D4302;
    h4 {
        font-weight: 400;
        font-size: 50px;
        line-height: 30px;
    }  
    &-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        h5 {
            font-size: 30px;
        }
        .common-price, 
        .common-status {
            font-size: 20px;
            line-height: 20px;
            font-weight: 400; 
        }
    }
   
}

.avatar-player-status {
    // animation-name: fadeInDropdown;
    animation-name: fadeInDropdown2;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.25, 0.5, 2.25);
    animation-fill-mode: forwards;
}

.avatar-player-status-infinite {
  animation-name: fadeInDropdown2;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.25, 0.5, 2.25);
  animation-fill-mode: forwards;
}

@keyframes fadeInDropdown {
    0% {
        height: 0;
        opacity: 0;
    }
    30% {
        opacity: 1;
        height: 30px;
    }
    80% {
        opacity: 1;
        height: 30px;
    }
    100% {
        opacity: 0;
        height: 0;
    }
}

@media screen and (max-width: 1200px) {
  @keyframes fadeInDropdown2 {
    0% {
      height: 0;
      opacity: 0;
    }
    30% {
      opacity: 1;
      height: 20px;
    }
    90% {
      opacity: 1;
      height: 20px;
    }
    100% {
      height: 0;
      opacity: 0;
    }
  }
}

@media screen and (min-width: 1201px) {
  @keyframes fadeInDropdown2 {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        opacity: 1;
        height: 25px;
    }
  }
}



.myTurn {
    background: linear-gradient(to right, #fff 50%, #FFDF00 50%);
    border-radius: 100%;
    animation: loader-circle-time 15s steps(800, start);
  }
  
  .myTurn:after {
    content: "";
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    animation: loader-circle-mask 15s steps(250, start);
    transform-origin: 100% 50%;
  }
  
  @keyframes loader-circle-time {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader-circle-mask {
    0% {
      background: #FFDF00;
      transform: rotate(0deg);
    }
  
    50% {
      background: #FFDF00;
      transform: rotate(-180deg);
    }
  
    50.01% {
      background: #fff;
      transform: rotate(0deg);
    }
  
    100% {
      background: #fff;
      transform: rotate(-180deg);
    }
  }
  
  .startGame { 
    animation-name: fadeOut;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    display: none;
    span {
      color: #FFDF00;
      font-size: 70px;
      letter-spacing: 10px;
      font-weight: bold;
    } 
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    } 
    100%{
      opacity: 0;
    }
  }