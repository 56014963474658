.ant-select {
    height: 100% !important;

    .ant-select-selector {
        padding: 10px !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
    }
}

.ant-input-disabled {
    background-color: #d9d9d9a8 !important; 
    color: rgb(0 0 0 / 68%) !important;
}


.ant-input {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}

.ant-input-password {
    .ant-input {
        box-shadow: unset !important;
    }
}


.ant-select {
    &.toolbarRoomSelect {
        .ant-select-selector {
            padding: 1px 5px !important; 
        }
    }
}

.ant-input,
.ant-select {
    &.input-error {
        border: 1px solid #e51818;
        box-shadow: rgb(241 137 137 / 25%) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;;
    }
}

.ant-select {
    &.no-padding {
        .ant-select-selector {
            padding: 0px 10px !important;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
        }
    }
}

.ant-otp-input {
    font-size: 15px;
    padding: 10px;
}

@media screen and (max-width: 1350px) {
    .ant-otp-input {
        font-size: 13px !important;
        padding: 6px !important;
    }
}